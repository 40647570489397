.companyList {
    display: block;
    float: left;
    width: 100%;

    .item {
        padding: 4px 0;
        width: 100%;
        height: 40px;
        line-height: 28px;        

        div {
            float: left;

            &:first-child {
                width: 75%;
            }

            &:last-child {
                margin: 2px 8px;
                font-size: 18px;
            }
        }
    }
        
}

.utilityPipelineList {
    display: block;
    float: left;

    .item {
        height: auto;
    }
}

.upList {

    .upListItem {
        border-bottom: 1px solid #cdcdcd;
        padding: 10px 0;
    }

    .utilName {
        font-size: 16px;
        text-align: right;
    }

    .plItem {
        display: block;
        float: left;
        width: 100%;
    }
}