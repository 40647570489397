.noContracts {
    line-height: 40px;
}

.subTableWrap {
    padding: 10px 0 20px 0;
}

.pipelineAvailItem {
    padding: 6px 0;
}

.switchWrap {
    padding: 10px 0;
}

.search {
    padding-top: 6px;
    input {
        height: 100% !important;
    }

    button {
        margin: 0 4px !important;
        height: 94%;
    }
}

.emptyLoad {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
    width: 100%;
}

.filterTag {
    max-width: 130px !important;
}

.selectDatePrompt {
    line-height: 50px;
}

.createContractBtn {
    float: right;
    margin-bottom: 10px;
}


    .dateOptionsSection {
        height: 96px;
        width: 100%;
        
        &>div {
            padding-left: 0px;
            height: 66px;
            float: left;
        }

        .error {
            width: 100%;
            margin: 10px 0;
        }
    }

    .submitDelLocBtn {
        float: left !important;
        width: 100%;
    }

    .btnMenu {
        button {
            width: 100%;
        }

        & > div {
            width: 100%;
        }
    }

    .plrEntry {
        strong {
            margin-right: 5px;
        }
    }

    .utilPipelineSection {
        button {
            width: auto;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    .pipelineRecordList {

        section {
            line-height: 40px;
            margin-bottom: 5px;

            button {
                float: left;
                height: 40px;
                padding: 0px 10px;
            }
        }
        
    }

    .delLocSection {
        height: 125;
        overflow: 'auto';
    }

.linkContractModal {

    .stepsContent {
        padding: 40px 0;
    }

    .exportSection {
        margin-top: 20px;
    }

}

.btnMenu {
    height: 54px;
}

.contractInfoSection {
    p {
        margin-bottom: 8px;
    }
}