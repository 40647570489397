.createInterruptibleBtn {
    float: right;
}

@media (max-width: 768px){
    .createInterruptibleBtn {
        width: 100%;
    }
}

.createInterruptibleModal {
    .btnMenu {
        height: 54px;
    }
}

.interruptibleInfoSection {
    p {
        margin-bottom: 8px;
    }
}

.editable-row .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}