@import 'components/global.scss';

.authWrap {
    max-width: 1000px;
    margin: 3.8146972656em auto;
    background: white;
    border: 1px solid $c-dark;
    border-radius: $section-radius;
    box-shadow: rgb(0 0 0 / 30%) 0px 24px 14px -6px;
    overflow: hidden;

    .authSection:first-child {
    }

    .carouselWrap {
        float: left !important;
        width: 100% !important;
    }

    .dmImage {
        margin-top: 80px;
        float: left;
        margin-left: 3%;
    }

    input {
        border-color: $c-dark !important;
    }

    .authSection {
        section {
            border: none !important;
        }

        input {
            border: 1px solid #cdcdcd;
        }

        .slideWrap {
            border-radius: 4px;
            width: 96%;
            height: 442px;
            margin: 34px auto;

            background: linear-gradient(-45deg, #d6d9e6, #d9d6e9, #f1ecd2, $off-blue);
            background-size: 400% 400%;
            animation: gradient 15s ease infinite;
        }

        @keyframes gradient {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
    }
    
}

@media (max-width: $mobile){
    .authMain {
        height: 100vh;
    }

    .authWrap {
        display: block;
        border-radius: 4px 4px 0 0;
        border-right: none;
        border-left: none;
        border-bottom: none;
        box-shadow: none;
        height: 100%;
        margin-bottom: 0;

        .authSection {
            width: 100% !important;
            max-width: 100% !important;
        }

        .authSection:first-child {
            display: none;
        }
    }

}
