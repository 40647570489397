@import 'components/global.scss';

.ant-pagination {
    padding-top: 20px;
}

.dealLogsTableSection {
    section {
        margin-bottom: 20px;
    }
}

.exportBtn {
    float: right;
}

.fixPriceSlotModal {
    .currentTermSection {
        margin-bottom: 20px;
    }

    .btnMenu {
        height: 54px;
    }
}

.addFixPriceSlotModal {
    .btnMenu {
        height: 54px;
    }
}

.summaryWrap {
    padding-bottom: 40px;
}

.pipelineAvailItem {
    padding: 6px 0;
}