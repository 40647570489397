@import 'components/global.scss';
@import url('https://fonts.cdnfonts.com/css/neue-haas-grotesk-text');

html {

  height: 100%;
  font-size: 62.5%;

}

#root {

  height: 100%;

}

body {

  color: $off-black !important;
  height: 100% !important;
  font-size: 1.4rem !important;
  //font-family: 'Source Sans Pro', helvetica, arial, sans-serif !important;
  font-family: 'Neue Haas Grotesk Text', sans-serif;
  
  &.color {

    background-color: #fff;
  }

  &.disableOverflow {

    overflow-y: hidden;

  }

  @media (min-width: $mobile){

    font-size: 1.5rem;

  }

  @media (min-width: 1200px){

    font-size: 1.6rem;

  }
}

.ant-dropdown-button {
  .ant-btn {
    border-radius: $radius 0 0 $radius !important;
  }

  .ant-btn-icon-only {
    border-radius: 0 $radius $radius 0 !important;
  }
}

.ant-picker, .react-multi-email, .ant-tag {
  border-radius: $radius !important;
}

.ant-tag {
  border: none !important;
}
