.dailyNominationFormWrap {
    width: 100%;
    height: 90%;
    position: relative;

    .utilPipelineSection {
        padding-bottom: 20px;
    }

    .pipelineRecordCount {
        color: #ffffff;
    }

    .utilityMenu {
        padding-top: 30px;
    }

    .selectDatePrompt {
        line-height: 50px;
    }

    .quantityError {
        color: red;
    }

    .quantitySuccess {
        color: green;
    }

    .pipelineRecordForm {
        position: absolute;
        background: rgb(216 216 216);
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 1000;

        section {
            padding: 20px;
        }
    }

    .utilHeaderSection {
        display: inline-block;
    }

    .plrEntry {
        strong {
            margin-right: 5px;
        }
    }

    .btnMenu {
        padding-top: 20px;
    }

    .error {
        margin: 5px 0;
    }
}

.interruptibleFlowForm {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 500px;
}

.interruptibleInfoSection {
    margin-top: 1em;

    p {
        margin-bottom: 2px;
    }

    p:first-child {
        font-weight: 600;
    }

    .maxError {
        color: red;
        border: 1px solid red;
        border-radius: 6px;
        background: #ffe6e6;
        padding: 2px 10px;
    }
}

.tableScrollWrap {
    height: 200px;
    overflow-y: scroll;
    padding-top: 17px;
}