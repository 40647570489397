@import 'components/global.scss';

.btnMenu {
    height: 66px;
}

.totalWrap {
    padding: 10px 0;
}

.scrollWrap {
    overflow-y: auto;
}

.selectDatePrompt {
    line-height: 50px;
}

.utilChartWrapper {
    padding: 20px 20px 20px 70px;
}

@media (max-width: 768px) {

    .utilChartWrapper {
        padding: 20px 20px;
    }
    
}

@media (max-width: $mobile) {

    .utilChartWrapper {
        display: none;
    }

}