.createLocationBtn {
    float: right;
}

.dataList {
    display: block;
    width: 100%;

    li {
        padding: 10px 0;

        .title { 
            margin-right: 20px;
        }

        .passwordTitle { 
            float: left;
        }
    }
}

.multiEmail {
    margin-bottom: 20px;
}

@media (max-width: 768px){
    .createLocationBtn {
        width: 100%;
    }
}