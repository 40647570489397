@import 'components/global.scss';

.filterBtnWrap {
    button {
        margin-top: 26px;
    }
}

.ant-pagination {
    padding-top: 20px;
}

.dealLogsTableSection {
    section {
        margin-bottom: 20px;
    }
}

.exportBtn {
    float: right;
}

.fixPriceSlotModal {
    .currentTermSection {
        margin-bottom: 20px;
    }

    .btnMenu {
        height: 54px;
    }
}

.addFixPriceSlotModal {
    .btnMenu {
        height: 54px;
    }

    .totalWrap {
        padding: 10px 0;
    }
}
