.newDealMenuSection {
    padding-bottom: 20px;

    section {
        height: 100%;
    }

    .addSpotDealBtnWrap {
        float: left;
    }
    
}

.icon {
    font-size: 32px;
}

.spotDealNote {
    height: 40px;
}

.mainLeft {
    button {
        width: 100%;
    }
}

.mainRight {

    .createNewDateFlowBtn {
        margin-top: 26px;
        margin-bottom: 1.25em;
    }

    button {

        width: 100%;
    }
}

.dealMenuLeft {
    button {
        margin-right: 10px;
    }   
}

.dealMenuRight {
    button {
        float: right;
    }
}

.dealListFooter {
    height: 45px;
}

@media (max-width: 768px){
    .createNewDateFlowBtn {
        width: 100%;
    }

    .dealMenuLeft {
        button {
            width: 100%;
            margin: 10px 0;
        }
    }

    .dealMenuRight {
        button {
            width: 100%;
            margin: 10px 0;
        }
    }

    .dealListFooter {
        button {
            width: 100%;
        }
    }
}

.createDeal {
    .dateOptionsSection {
        height: 96px;
        width: 100%;
        
        &>div {
            padding-left: 0px;
            height: 66px;
            float: left;
        }

        .error {
            width: 100%;
            margin: 10px 0;
        }
    }
}

.dealTypeModal {
    .btnMenu {
        height: 66px;
    }
}

.dailyNominationFormWrap {
    width: 100%;
    height: 90%;
    position: relative;
    overflow-y: scroll;

    .quantityError {
        color: red;
    }

    .quantitySuccess {
        color: green;
    }

    button {
        width: auto;
        float: left;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .utilPLRecordCount {
        line-height: 30px;
        float: left;
        margin-right: 20px;
    }

    .pipelineRecordForm {
        position: absolute;
        background: rgb(216 216 216);
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 1000;

        section {
            padding: 20px;
        }
    }

    .overlay {
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.2);
    }

    .dailyNomScroll {
        //max-height: 300px;
        overflow-y: scroll;
        //-webkit-box-shadow: inset 0px 0px 13px 0px #000000; 
        //box-shadow: inset 0px 0px 13px 0px #000000;
        //padding: 0 10px;
    }

    .plrEntry {
        strong {
            margin-right: 5px;
        }
    }

    .pipelineRecordList {

        section {
            line-height: 40px;
            margin-bottom: 5px;

            button {
                float: left;
                height: 40px;
                padding: 0px 10px;
            }
        }
        
    }

    .pipelineRecordsWrap {
        width: 100%;

        .addPipeLineRecordBtn {
            margin: 36px 0;
        }
    }

    .btnMenu {
        height: 76px;
        padding-top: 20px;
    }

    .error {
        margin: 5px 0;
    }
}

.dealInfoSection {
    p {
        margin-bottom: 8px;
    }
}

.createContractBtn {
    float: right;
    margin-bottom: 10px;
}