.btnMenu {
    height: 54px;
}

.addEmailJobBtn {
    float: right;
}

@media (max-width: 768px){
    .addEmailJobBtn {
        width: 100%;
    }
}